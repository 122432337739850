/* You can add global styles to this file, and also import other style files */
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap')
@import "@fortawesome/fontawesome-free/css/all.css";
// PrimeNg
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    list-style: none;
}

.card {
    width: 415px;
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    background-color: #fff;
    margin: 1rem 1rem 1rem 0;
    box-shadow: 0 .25rem .5rem #a5afb3;

    background-color: #56c2c2;

    .card-head {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #edf1f7;
        font-size: 1rem;
        font-weight: 600;
    }

    .card-body {
        padding:1.5rem;
    }
}

